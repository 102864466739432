import React, {Component} from 'react';
import MonacoEditor from 'react-monaco-editor';

import copy from 'copy-to-clipboard';
import "./App.scss";

class SJson {
    static stringify(obj) {
        const jsonString = JSON.stringify(obj);
        let result = '';
        let isInString = false;

        for (let i = 0; i < jsonString.length; i++) {
            const char = jsonString[i];
            const nextChar = jsonString[i + 1];

            if (char === '"' && (i === 0 || jsonString[i - 1] !== '\\')) {
                isInString = !isInString;
            }

            if (!isInString) {
                if (char === '{') {
                    result += '\\{';
                } else if (char === ',') {
                    result += '\\,';
                } else {
                    result += char;
                }
            } else {
                result += char;
            }
        }

        return result;
    }

    static parse(sjsonString) {
        let jsonString = '';
        let isInString = false;

        for (let i = 0; i < sjsonString.length; i++) {
            const char = sjsonString[i];
            const nextChar = sjsonString[i + 1];

            if (char === '"' && (i === 0 || sjsonString[i - 1] !== '\\')) {
                isInString = !isInString;
            }

            if (!isInString) {
                if (char === '\\' && (nextChar === '{' || nextChar === ',')) {
                    continue; // Skip the escape character outside of strings
                }
            }

            jsonString += char;
        }

        return JSON.parse(jsonString);
    }
}

function escapeToCicdConfig(code) {
    try {
        // TODO: improve
        let p = JSON.parse(code);
        return SJson.stringify(p)
    } catch (e) {
        return "invalid json"
    }
}

function unescapeCicdConfig(code) {
    try {
        return JSON.stringify(SJson.parse(code), null, 4)
    } catch (e) {
        return "invalid json"
    }
}

class JsonEditor extends Component {
    copyCICD = () => {
        try {
            JSON.parse(this.state.code);
        } catch (e) {
            alert(`invalid JSON: ${e}`)
            return;
        }
        copy(escapeToCicdConfig(this.state.code))
    }

    constructor(props) {
        super(props);
        this.state = {
            code: '{"name": "John Doe", "age": 30}',
        };
    }

    editorDidMount = (editor, monaco) => {
        this.editor = editor;
        this.monaco = monaco;
    };

    formatDocument = () => {
        if (this.editor) {
            const unformatted = this.editor.getValue();
            const formatted = JSON.stringify(JSON.parse(unformatted), null, 2);
            this.editor.setValue(formatted);
        }
    };

    render() {
        const options = {
            selectOnLineNumbers: true,
            automaticLayout: true,
            formatOnType: true,
            formatOnPaste: true,
        };

        return (
            <div className="root">
                <h1>
                    Paste CICD config here
                </h1>
                <div className="cicd">
                    <textarea value={escapeToCicdConfig(this.state.code)}
                              onClick={e => console.log(e.target.select())}
                              onChange={
                                  (e) => {
                                      this.setState({code: unescapeCicdConfig(e.target.value)})
                                  }
                              }/>
                    <button onClick={this.copyCICD}>Copy CICD</button>
                </div>


                <div className="json-editor">

                    <h1>
                        Edit JSON here
                    </h1>

                    <MonacoEditor

                        height="600"
                        language="json"
                        theme="vs-dark"
                        value={this.state.code}
                        options={options}
                        onChange={(newValue, e) => {
                            this.setState({code: newValue});
                        }}
                        editorDidMount={this.editorDidMount}
                    />
                    <button onClick={this.formatDocument}>Format Document</button>
                </div>

            </div>
        );
    }
}

function App() {
    return (
        <div>
            <JsonEditor/>
        </div>
    );
}

export default App;
